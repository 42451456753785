<template>
  <div>
    <p>{{ $t('onboarding.sourceOfWealthInformation.desc') }}</p>

    <v-form
      v-model="isFormValid"
      @submit.prevent="submit"
    >
      <!-- SOURCE OF WEALTH -->
      <v-select
        :items="sourcesOfWealth"
        item-text="text"
        item-value="value"
        v-model="data.financialData.sourceOfWealth"
        :rules="rules.required"
        :label="`${$t('onboarding.sourceOfWealthInformation.fields.sourceOfWealth')} *`"
        outlined
    ></v-select>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import clone from '@/utils/clone';
import { required } from '@/utils/validators';

export default {
  name: 'SourceOfWealthInformation',
  data() {
    return {
      data: clone(this.$store.state.user.data),
      rules: {
        required,
      },
    };
  },
  computed: {
    ...mapGetters('onboarding', [
      'sourcesOfWealth',
      'editMode',
    ]),
    ...mapFields('onboarding', [
      'isFormValid',
    ]),
  },
  methods: {
    ...mapActions('user', [
      'updateUser',
      'setSubStage',
      'updateOnboarding',
    ]),
    async submit() {
      const input = {
        investor: {
          personal: {
            sourceOfWealthInformation: {
              sourceOfWealth: this.data.financialData.sourceOfWealth,
            },
          },
        },
        mainStage: 'personal',
        subStage: 'sourceOfWealthInformation',
      };
      try {
        await this.updateOnboarding({
          input,
          newData: this.data,
        });
        if (this.editMode) {
          this.$store.dispatch('onboarding/editMode', null);
          this.$router.push({
            name: 'Onboarding.Personal',
            params: {
              step: 'Overview',
            },
          });
        } else {
          this.setSubStage('overview');
        }
      } catch (error) {
        this.$notify.error(error.message);
      }
    },
  },
};
</script>
